import { Illustrations, IncrementSizes } from '@loveholidays/design-system';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { DesignSystemAsset } from '@DesignSystemComponents/DesignSystemAsset';
import { ColorsPalette } from '@UX/themes/types';

export interface IllustrationProps extends Pick<ClassNameProps, 'className'> {
  /**
   * The name of the illustration.
   */
  name: Illustrations;

  /**
   * The size of the illustration. Use increments size token here.
   */
  size: keyof IncrementSizes | (keyof IncrementSizes)[];

  /**
   * The color of the illustration. Use colour token here.
   */
  color?: keyof ColorsPalette;

  illustrationStyles?: SxStyleProp;
}

/**
 * Component to render illustrations.
 */
export const Illustration: React.FC<IllustrationProps> = ({ illustrationStyles, ...restProps }) => (
  <DesignSystemAsset
    type="Illustration"
    svgStyles={illustrationStyles}
    {...restProps}
  />
);
